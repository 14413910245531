.MobileMenu {

  background-color: rgba(62, 87, 103, .9);
  position: fixed;
  top: 6rem;
 left: calc((var(--sideGutter) + var(--gridGap)));
  right: calc((var(--sideGutter) + var(--gridGap)));


  border-top: 2px solid var(--accentColor);
  transition-property: transform;
  transform: scale(1, 0);
  transform-origin: top;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
	@media screen and (min-width: 1300px){
	  top: 9rem;
	}

  a {
	display: block;
	font-size: 1.5em;
	margin-bottom: .5rem;
	transition-property: opacity;
	opacity: 0;
	transition-duration: .15s;
  }
  & + main {
	section {
	  * {
		transition-duration: .25s;
		transition-timing-function: ease-in-out;

	  }
	}
  }
  &[data-openned="true"] {
	transform:scale(1,1);
	transition-duration: .25s;
	transition-timing-function: ease-in-out;
	padding: 2rem 3rem ;
	z-index: 50;
	z-index: 50;
	height: 100lvh;
	background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.5) 50%, rgba(0,0,0,0.14) 100%);
	a{
	  transition-duration: .25s;
	  transition-timing-function: ease-in-out;
	  transition-property: opacity;
	  opacity: 1;
	  transition-delay: .2s;
	  transition-duration: .1s;
	}
	& + main {
	  section {
		* {
		  transition-duration: .25s;
		  transition-timing-function: ease-in-out;
		  opacity: 0;
		}
	  }
	}
  }
}

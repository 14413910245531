.Modal {
  @screen lg {
	display: flex;

  }
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;

  justify-content: center;
  background: rgba(0,0,0,.8);
  align-items: center;
  & > * {
	flex: 0  1 37.5rem;
	@screen lg {
	  display: grid;
	}
	justify-content: center;
	min-height: 350px;
	align-items: center;
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}
	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: center;
	  justify-items: center;
	  align-items: center;
	  margin-top: 0 !important;
	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

}
.ModalBidFullImg {
  @media screen and (max-width: 740px) {
	display: grid;
	grid-template-rows: 1fr auto;
	align-items: center;
	align-content: center;
  }

	background-color: transparent !important;
  &:focus-visible {
	outline: none;
  }
  header {
	background-color: transparent !important;
  }
}
.ModalUpBalance{


  & > * {
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}
	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: center;
	  justify-items: center;
	  align-items: center;
	  margin-top: 0 !important;
	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }
  section {
	@apply px-8 pt-12 pb-8 border-gray-2 border content-center;
	background: rgba(36, 37, 41, .6);
	@screen tablet-max {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  right: 0;
	  display: grid;

	  left: 0;
	  border: 0;
	  border-radius: 0;
	  min-height: 100lvh;

	}
	[data-content="modal_company"] {
	  display: flex;
	  flex-direction: column;
	  gap: .5rem;
	  @screen tablet {
		flex-direction: row;
			justify-content: space-around;
	  }

	}
	footer {
	  @screen tablet-max {
		gap: .5rem;
	  }
	}
	header {
	  @apply pt-0 bg-none pl-0;
	  background: none !important;

	}
  }
}
.ModalBid, .ModalCarClasses {
  //display: flex;
  ////position: fixed;
  //left: 0;
  //right: 0;
  //z-index: 100;
  //bottom: 0;
  //top: 0;
  //justify-content: center;
  //background: rgba(0,0,0,.8);
  //align-items: center;
  & > * {
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}
	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: center;
	  justify-items: center;
	  align-items: center;
	  margin-top: 0 !important;
	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

  section {
	@apply tablet:px-10 tablet:py-12 border-gray-2 border;
	background: rgba(36, 37, 41, .6);
	@screen tablet-max {
	  //position: absolute;
	  top: 0;
	  bottom: 0;
	  right: 0;
	  //display: grid;
	  //align-content: center;
	  left: 0;
	  border: 0;
	  border-radius: 0;
	  max-height: 100dvh !important;
	  //margin: 5dvh 0 !important;
	  //min-height: 100lvh;
	  footer {
		display: grid;
	  }
	}
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	  min-height: auto !important;
	}
  }

}
.ModalSupport {

  & > * {
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}

	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: flex-end;
	  justify-items: center;
	  align-items: center;

	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

  section {
	@apply px-1 pt-16 pb-4 border-gray-2 border;
	background: rgba(36, 37, 41, .6);
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	}
	@screen tablet {
	  @apply px-8 pt-16 pb-8 border-gray-2 border;
	}
  }
}
.ModalCarClasses {
  @apply tablet-max:py-0;
  section {
	@apply  tablet:py-8 border-0;
	background-color: transparent !important;
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	}
  }
}

@-webkit-keyframes color-change-2x {
  0% {
	//stop-color: #081D16;
	opacity: 0.4;
  }
  25% {
	//stop-color: #081D16;
	opacity: 0.6;
  }

  40% {
	//stop-color:  #06593F;
	opacity: 0.7;
  }
  60% {
	//stop-color: #00FFAE;
	opacity: 0.8;
  }

  100% {
	//stop-color: rgba(3, 24, 18, 0.69);
	opacity: 1;
  }
}

@keyframes color-change-2x {
  0% {
	//stop-color: #081D16;
	opacity: 0.4;
  }
  25% {
	//stop-color: #081D16;
	opacity: 0.6;
  }

  40% {
	//stop-color:  #06593F;
	opacity: 0.7;
  }
  60% {
	//stop-color: #00FFAE;
	opacity: 0.8;
  }

  100% {
	//stop-color: rgba(3, 24, 18, 0.69);
	opacity: 1;
  }
}

@-webkit-keyframes scale-in-ver-top {
  0% {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 100% 0%;
	transform-origin: 100% 0%;
	opacity: 1;
  }
  100% {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 100% 0%;
	transform-origin: 100% 0%;
	opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 100% 0%;
	transform-origin: 100% 0%;
	opacity: 1;
  }
  100% {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 100% 0%;
	transform-origin: 100% 0%;
	opacity: 1;
  }
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top .25s cubic-bezier(.25, .46, .45, .94) both;
  animation: scale-in-ver-top .25s cubic-bezier(.25, .46, .45, .94) both
}

@keyframes wipe-in-down {
  0% {

	opacity: 0;
  }
  15% {
	clip-path: inset(0 0 100% 0);
  }
  85% {
	clip-path: inset(0 0 0 0);
  }
  100% {
	opacity: 1;
  }
}

[transition-style="in:wipe:down"], .wipe-in-down {
  animation: 12.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-11 21:38:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
	-webkit-transform: translateY(1000px);
	transform: translateY(1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
	-webkit-transform: translateY(1000px);
	transform: translateY(1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-11 21:39:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
	-webkit-transform: translateX(1000px);
	transform: translateX(1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
	-webkit-transform: translateX(1000px);
	transform: translateX(1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-11 21:40:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
	-webkit-transform: translateX(-1000px);
	transform: translateX(-1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-11 21:50:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
  100% {
	-webkit-transform: translateX(1000px);
	transform: translateX(1000px);
	opacity: 0;
  }
}

@keyframes slide-out-right {
  0% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
  100% {
	-webkit-transform: translateX(1000px);
	transform: translateX(1000px);
	opacity: 0;
  }
}

.slide-out-right {
  -webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-right-500 {
  -webkit-animation: slide-out-right 0.25s 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: slide-out-right 0.25s 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes slide-in-left {
  0% {
	-webkit-transform: translateX(-1000px);
	transform: translateX(-1000px);
	opacity: 0;
  }
  100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-left-500 {
  -webkit-animation: slide-in-left 0.25s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.25s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

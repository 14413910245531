.Section {
	grid-column: 2/-2;
  	display: grid;
  	grid-template-columns: subgrid;
	position: relative;
  align-self: stretch;
  gap: 2.5rem;
  z-index: 5;
  align-items: start;
  min-height: 45rem;
  grid-template-rows: min-content;
  //padding-bottom: 1rem;
  &[data-variant="centered"] {
	align-self: center;
	align-items: center;
	//grid-row: 2/3;
	> div {
	  align-self: center;
	}
  }
  &[data-variant="withSuffix"] {
	grid-template-rows: min-content 1fr;
  }
  @screen tablet-max {
	padding-bottom: 2rem;
	margin-bottom: -3rem;
  }
  //@screen tablet-big-max {
	//div + div[data-variant="withGapOnly"] {
	//  margin-left: -1.375rem;
	//  margin-right: -1.375rem;
	//}
  //}


  //> *[class^="Panel"] {
	//grid-column: 2/-2;
  //}
  //@media screen and (min-width: 744px){
	//> *[class^="Panel"] {
	//  grid-column: 2/-2;
	//}
  //}
  //@media screen and (min-width: 1300px){
  //
	//> *[class^="Panel"] {
	//  grid-column: 2/-2;
	//}
  //}
  > [data-variant="dataPadding"], > [data-variant="textPadding"] , > [data-variant="default"] {
	align-self: stretch;

	//max-height:clamp(50dvh, 100%, 150dvh);
	//& > * {
	//  height: 100%;
	//}
  }
}

.CarHelper {

}
.row {
	[data-panel="body"] > ul {
	cursor: pointer;
	&:hover {


	background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
	background-attachment: fixed;
	color: #ffffff;
  	}
  }
}
.listItem {
	@screen tablet-max {
	  padding-left: 1.5rem;
	  &:not(:first-child):before {
		@apply  text-[#929398] text-xss uppercase;
	  }
		&:nth-child(1) {
		  &:before {
			content: 'Класс:';
			margin-right: 4px;
		  }
		  margin-bottom: .5rem;
		}
	  	&:nth-child(2) {
		  &:before {
			content: 'Тип:';
			margin-right: 4px;
		  }
		}

	  	&:nth-child(3) {
		  &:before {
			content: 'Пример:';
			margin-right: 4px;
		  }
		}
	  	&:nth-child(4) {
		  &:before {
			content: 'Шиномонтаж:';
			margin-right: 4px;
		  }
		}
	  	&:nth-child(5) {
		  &:before {
			content: 'Мойка:';
			margin-right: 4px;
		  }
		}
	  	&:nth-child(6) {
		  &:before {
			content: 'Эвакуация:';
			margin-right: 4px;
		  }
		}


	}
}

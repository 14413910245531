.Checkbox {
  //font-family: Montserrat, sans-serif;
  ////line-height: 1.1;
  //display: flex;
  //font-weight: 500;
  //align-items: center;
  //gap: .625rem;
  //overflow: hidden;
  //position: relative;
  //margin-bottom: 0;
  //color: currentColor;


	input[type="checkbox"][data-availible="false"]:disabled:not(:checked) {
	  background-color: #242529;
	  color: var(--form-control-disabled);
	  cursor: not-allowed;
	  opacity: 1;
	  border-color: #343437;
	}


  //input[type="checkbox"] {
	///* Add if not using autoprefixer */
	//-webkit-appearance: none;
	///* Remove most all native input styles */
	//appearance: none;
	///* For iOS < 15 */
	//background-color: transparent;
	///* Not removed via appearance */
	//margin: 0;
	//height: 1rem;
	//width: 1rem;
	//padding: .125rem;
	//color: currentColor;
	//max-width: 1rem;
	//max-height: 1rem;
	//border: 1px solid #606163;
	//border-radius: 0;
	//place-content: center;
	////transform: translateY(-0.075em);
  //
  //}
  //
  //input[type="checkbox"]::before {
	//content: "";
	//display: block;
	//width: 0.625rem;
	//height: 0.625rem;
	////clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	//transform: scale(0);
	//transform-origin: center;
	//transition: 120ms transform ease-in-out;
	//box-shadow: inset 1em 1em var(--accentColor);
	/////* Windows High Contrast Mode */
	//background-color: CanvasText;
  //}
  //
  //input[type="checkbox"]:checked::before {
	//transform: scale(1);
  //}
  //
  //input[type="checkbox"]:focus {
	//outline: 0;
	//outline-offset: max(2px, 0.15em);
  //}
  //
	//@screen tablet-max {
	//  font-size: .75rem;
	//}
}

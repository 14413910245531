.cardform_step {
  @apply px-4 py-5 border-gray-2 border rounded-md bg-gray-3 flex flex-col;
  @screen lg {
    aspect-ratio: 300/200;
  }
  max-height: 12.5rem;
  footer {
    margin-top: auto;
    display: flex;
    & > * {
      flex: 1;
    }
  }
  &:hover {
    background: rgba(36, 37, 41, 0.6);
    border-color: rgba(96, 97, 99, 1);
    cursor: pointer;
  }
}

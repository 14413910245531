.Button:not([data-variant="text"],[data-variant="icon"]) {
user-select: none ;
  text-decoration: none;
  line-height: 1;
  border-radius: 6.5rem;
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;
  background: transparent;
  height: 3.125rem;
  max-height: 3.125rem;
  color: white;
  font-size: 1rem;
  border: 1px solid var(--colorWhite);
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  transition: all .15s ease-in-out;

  &:hover, &:active {
	background: transparent;
	border-color: var(--accentColor);
	transition: all .35s ease-in-out;
	color: var(--accentColor);
  }

  &[data-size="base"] {
	height: 3.125rem;
	text-transform: initial;
	font-size: .875rem;
	padding: 0 3.125rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: normal;
	font-family: 'Montserrat';

	@media screen and (max-width: 743px) {
	  padding-left: 1.75rem;
	  padding-right: 1.75rem;
	  justify-self: center;
	  width: 100%;
	}
  }

  &[data-size="sm"] {
	padding: 0 1.875rem;
	height: 2.5rem;
	min-height: 2.5rem;
	font-weight: 500;
	font-family: Montserrat, sans-serif !important;
	text-transform: initial !important;

	@screen lg-max {
	  padding: 0 1.125rem;
	  font-size: .75rem;
	  height: 2rem;
	  min-height: 2rem;
	}
  }

  &[data-size="xs"] {
	padding: 0 1rem;
	height: 2rem;
	min-height: 2rem;
	font-weight: 500 !important;
	font-family: Montserrat, sans-serif !important;
	text-transform: initial !important;
	color: black;
	@screen lg-max {
	  padding: 0 1rem;
	  font-size: .75rem;
	  height: 2rem;
	}
  }


  &[data-variant="accent"] {
	background: var(--accentColor);
	color: var(--colorBlack);
	border-color: var(--accentColor);
	text-transform: uppercase;
	@apply font-sans font-semibold;
	align-items: center;

	&:hover {
	  background: var(--accentColor);
	  border-color: var(--accentColor);
	  color: black;
	  backdrop-filter: blur(0);
	  filter: drop-shadow(0 0 26px #00ffaebd);
	 

	}
   &:focus {
	 background: var(--accentColor);
	 border-color: var(--accentColor);
	 color: black;
   }
  }


  &[data-variant="default"] {
	background: transparent;
  }

  &[data-variant="tech"] {
	background: transparent;
	padding: 0 1.875rem;
	height: 2.5rem;
	font-size: 1rem;
	color: var(--accentColor);
	border-color: var(--accentColor);

	&:hover, &:focus {
	  background: var(--accentColor);
	  border-color: var(--accentColor);
	  color: black;
	}
  }


  &[data-variant="accent-outline"] {
	background: transparent;
	border: 1px solid var(--accentColor);
	text-transform: uppercase;
	align-items: center;
	@apply font-medium;

	color: var(--accentColor);

	&:hover, &:focus {
	  background: var(--accentColor);
	  border-color: var(--accentColor);
	  color: black;
	}
  }
  &[data-variant="cancel"] {
	background: transparent;
	border: 1px solid ;
	text-transform: uppercase;
	align-items: center;
	@apply font-medium border-white/60 text-white/60;
* {
  transition: all initial !important;
}

	&:hover, &:focus {
	  //background: var(--colorWhite);
	  @apply font-medium border-accent text-accent;
	  transition: all initial !important;

	}
  }


  &[data-variant="outline"] {
	border-color: var(--colorWhite);
	color: var(--buttonOutlineText);
	font-size: .75rem;
	font-weight: 400;

	position: relative;
	padding: 1px;
	//-webkit-background-clip: text !important;
	//-webkit-text-fill-color: transparent !important;
	&:hover, &:focus {
	  background: var(--accentColor);
	  border-color: var(--accentColor);
	  color: black;
	}
	&:before {
	  content: '';
	  border-radius: 1.25rem;
	  background: var(--color);
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  z-index: -1;
	  left: 0;
	}

	&:after {
	  content: '';
	  border-radius: 1.25rem;
	  border: 1.25rem solid transparent !important;
	  background: #18191F !important;
	  position: absolute;
	  top: 1px;
	  right: 1px;
	  bottom: 1px;
	  z-index: -1;
	  left: 1px;
	}

  }


  &[data-variant="outline"] {
	padding-left: .75rem;
	padding-right: .75rem;
  }

  &[data-directory="customer"] {
	--color: linear-gradient(-120.44deg, #3FECAE 24.44%, #0500FF 119.81%);
	background: linear-gradient(-120.44deg, #3FECAE 24.44%, #0500FF 119.81%);
	border: 0;
  }

  &[data-directory="admin"] {
	background: linear-gradient(120deg, #D80BEA 7.5%, #00FFAE 42.5%, #00FFAE 57.5%, #D80BEA 93.5%);
	background-size: 200%;
	background-position: 0 50%;
	border: 0;
	--color: linear-gradient(-120.68deg, #D80BEA 15.78%, #00FFAE 86.68%);

  }

  &[data-directory="performer"] {
	border: 0;
	background: linear-gradient(-90.76deg, #00A8AA 0.27%, #F0F600 87.66%);
	--color: linear-gradient(-90.76deg, #00A8AA 0.27%, #F0F600 87.66%);
  }
  @keyframes directoryClick {
	from {
	  background-position-x: 0;
	}
	to {
	  background-position-x: 100%;
	}
  }

  &[data-directory="directory"] {
	--position: 0;
	border: 0;
	color: black;
	background: var(--gradient-directory-200);
	background-size: 200%;
	background-position:  var(--position);
	--color: var(--gradient-directory);
	&:hover {
	  --position: 100%;
	}
	&:active, &:focus, &:focus-within, &:focus-visible, &:hover  {
	  transition: all 0.3s ease-in-out;
	  background-position-x: var(--position);
	}

  }

}

.Button[data-disabled="true"], .Button[disabled] {
  opacity: .5;
  filter: grayscale(1);
  pointer-events: none;
}
.Button[data-variant="text"] {
  cursor: pointer;
  font-size: 0;
  @apply font-body  hover:text-accent lg:text-sm;

  svg {
	min-width: 1.5rem;
	min-height: 1.5rem;
	@screen lg {
	  min-width: 1rem;
	  min-height: 1rem;
	}
  }
}

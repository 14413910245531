.CardFeaturesCircle {
  display: flex;
  width: 22rem;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;

  margin: auto;

  &:nth-child(1) {
	translate: 5.375rem -17.375rem;
	background: linear-gradient(360deg, rgba(19, 255, 113, 0.7) -9.57%, rgba(0, 183, 125, 0.337864) 42.21%, rgba(0, 129, 185, 0) 77.35%);;
  }

  &:nth-child(2) {
	translate: -3.625rem 5.25rem;
	background: linear-gradient(240deg, rgba(19, 255, 113, 0.7) -9.57%, rgba(0, 183, 125, 0.337864) 42.21%, rgba(0, 129, 185, 0) 77.35%);;
  }

  &:nth-child(3) {
	translate: 14.625rem 13.25rem;
	background: linear-gradient(120deg, rgba(19, 255, 113, 0.7) -9.57%, rgba(0, 183, 125, 0.337864) 42.21%, rgba(0, 129, 185, 0) 77.35%);;
  }

  h4 {
	position: relative;
	padding: .875rem 1.25rem .875rem 1.25rem;
	text-align: center;
	margin-bottom: 0;
	line-height: 1.5;
	font-size: 1.5rem;

	&:after {
	  content: "";
	  /* width: 100%; */
	  height: .125rem;
	  left: 0;
	  display: block;
	  position: absolute;
	  bottom: 0;
	  background: linear-gradient(-310.21deg, #13FF71 29.26%, rgba(0, 183, 125, 0.482663) 72.22%, rgba(0, 129, 185, 0) 440%);
	  border-radius: .125rem;
	  right: 0;
	}
  }

  p {
	margin-top: .75rem;
	max-width: 13.625rem
  }
}

.PermissionTable {
  display: grid;
  gap: 2rem;
  @screen tablet-max {
    gap: 1.25rem .75rem;
  }
}

.PermissionTableHeader {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  @screen tablet-max {
    grid-template-columns: 1.25fr repeat(4, .75fr);
    padding-left: .75rem;
    padding-right: .75rem;
    margin: 0 -.75rem;
    font-size: 8px;
  }

  & > *:first-child {
	text-align: initial;
  }

  background-color: #090909;
  color: #929398;

  text-transform: uppercase;
  line-height: .75rem;
  @apply px-8 py-3 font-medium text-xss;
}

.PermissionTableRow {

  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  @apply font-medium text-xs px-8;
  & > *:first-child {

    @screen tablet-max {
	  font-size: .625rem;
    }
	color: white;
  }
  @screen tablet-max {
    grid-template-columns: 1.25fr repeat(4, .75fr);
    padding-left: .75rem;
    padding-right: .75rem;
    margin: 0 -.75rem;
    font-size: 8px;
  }
}

@layer {
  .DList {

	dt {
	  @apply text-gray-2 font-medium mb-1.5;
	  line-height: 1;
	}

	dd {
	  @apply text-base font-sans font-semibold;
	  line-height: 1.25rem;
	  &[data-directory="customer"] {
		--color:  var(--gradient-customer-200);
		background:  var(--gradient-customer-200);
		border: 0;
		background: var(--gradient-customer-200);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	  }

	  &[data-directory="admin"] {
		background: var(--gradient-admin-200);
		background-size: 200%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-position: 0 50%;
		border: 0;
		--color: var(--gradient-admin-200);
	  }

	  &[data-directory="performer"] {
		background: var(--gradient-performer-200);
		background-size: 200%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-position: 0 50%;
		border: 0;
		--color: var(--gradient-performer-200);
	  }
	}

  }
}

.Layout {
  display: grid;
  grid-template-columns: var(--grid);
  grid-template-rows: 4.5rem 1fr auto;

  min-height: calc(100vh);
  gap: 0 var(--gridGap);
  overflow: hidden;
  position: relative;

  main {
	display: grid;
	grid-column: 1/-1;
	grid-template-columns: subgrid;
	@supports not (grid-template-columns: subgrid) {
	  grid-template-columns: var(--grid);
	  gap: 0 var(--gridGap);
	}
	@screen desktop {
	  padding-bottom: .75rem;
	}

  }
  //***** no scrollbar

  //@screen desktop {
	//max-height: 100lvh;
  //}
  //
  //&[class*="page-account"] {
	//min-height: calc(100svh - 2.75rem);
  //}

}

.main {
  @media screen and (min-width: 744px){
	padding: 7.5rem 0;
	> *[class^="Panel"] {
	  grid-column: 1/-1;
	}
  }
  @media screen and (min-width: 1300px){

	> *[class^="Panel"] {
	  grid-column: 2/-2;
	}
  }
}

.Tabs {
  display: block;
  &[data-variant="bid-tabs"] {
    @screen tablet-max {

    }
  }
}
.tabHeaderWrapper {
  display: contents;
  @screen tablet-max {
    display: block;
    overflow: hidden;
    @apply border-b border-gray-2;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    overflow-x: scroll;
    margin: 0 -.75rem;
    //scroll-margin: 0 4rem;
    //padding: 0 1.5rem;
    //scroll-snap-type: both;
    //width: 100lvw;
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
    ul {
      width: max-content;
      padding: 0 .75rem;
    }
    & + [data-name] {
      //grid-template-columns: 1fr;
      dl {
        grid-column: 1 / -1 !important;
      }
      > div {
        grid-column: 1 / -1 !important;
        //margin-left: -1.25rem;
        //margin-right: -1.25rem;
      }
    }
  }

}
.tabHeader {
  @apply list-none text-xss uppercase text-gray-2 font-medium py-3.5 -mt-3.5 border-b-2 border-transparent;
  letter-spacing: 0.05em;
  cursor: pointer;
  &[data-state="true"] {
	@apply border-accent text-accent;
  }
  &:hover, &:focus {
    @apply border-accent text-accent opacity-60;
  }
}
.containerHeader {
  display: flex;
  @apply tablet:border-b tablet:border-gray-2 gap-6;
}
.tabPanel {
  @apply  grid grid-cols-2 gap-y-6 gap-x-4 grid-flow-col;
  grid-template-rows: repeat(5, minmax(0, auto));
  &[data-name="info"] {
    @apply grid-cols-3;
    grid-template-columns: 1.5fr 2fr 0.5fr;
  }
  &[data-company-type="performer"] {
    @apply grid grid-cols-2;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow:  column;
    > * {

      flex: 1 1 20rem;
      &:last-child {
        grid-column: 2;
        grid-row: 4;
      }
      &:nth-child(4) {
        grid-column: 2;
        grid-row: 1;
      }
      &:nth-child(5) {
        grid-column: 2;
      }
      &:nth-child(6) {
        grid-column: 2;
      }



    }
  }
  &[data-name="filials"] {
    [data-label="state"] {
      &:before {
        content: none;
      }
    }
  }

}
.tabPanelPure {
  @apply  grid grid-cols-2 gap-y-12 gap-x-4;




}

.Status {
	width: auto;
  min-width: 4rem;
  	display: flex;
  color: black;
  height: 1rem;
  line-height: 1 !important;
	font-size: .5rem;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: lowercase;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;

  &[data-variant='Новая'], &[data-rev-varian='Новая'],  {
	background: #FBFF4A;
  }
  &[data-variant='В обработке'], &[data-rev-varian='В обработке'],  {
	background: rgb(143, 132, 131);
  }
  &[data-variant='Разбор'], &[data-rev-varian='Разбор'],  {
	background: rgb(242, 224, 162);
  }
  &[data-variant='Выполнена'],  &[data-rev-varian='Выполнена'] {
	background: rgb(0, 255, 224);
  }
  &[data-variant='Завершена'], &[data-rev-varian='Завершена'], {
	background-color: #00D1FF;
  }
  &[data-variant='Согласовано'], &[data-rev-varian='Согласовано'],  {
	background: rgb(0, 255, 178);
  }
  &[data-variant='Отменена'], &[data-rev-varian='Отменена'],  {
	background: rgba(244, 86, 95, 1);
  }
  &[data-variant='Ожидает'], &[data-rev-varian='Ожидает'],  {
	background: rgb(248, 152, 64);
  }
  &[data-variant='В работе'], &[data-rev-varian='В работе'],  {
	background: rgb(255, 199, 1);
  }
  &[data-variant='Ждет оплаты'], &[data-rev-varian='Ждет оплаты'],  {
	background: #FF4D01;
  }




  &[data-size='base'] {
	padding: 5px .625rem 6px;
	height: 2rem;
	width: initial;
	font-size: 1rem;
  }

}

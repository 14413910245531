.UserMenu {
	display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  //max-width: 20rem;
  //width: 100%;
  @screen lg-max {
	flex-direction: row-reverse;
	flex: 1 1 calc(100vw - 40px - 40px - 10px - 20px);
	margin-right: 10px;
	grid-column: 1/3;
  }

}

.notification {
  //padding: .75rem;
  position: relative;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: .625rem;
  border: 1px solid transparent;
  background-color: #181818;
  &[data-msg="true"] {
	border: 1px solid #FBFF4A;
  }

  display: flex;
  a {
	margin: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 100%;
  }
  span {
	background-color: #FBFF4A;
	position: absolute;
	color: black;
	font-size: .625rem;
	font-weight: 600;
	border-radius: .5rem;
	min-width: .875rem;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: -.5rem;
  }
}
.user {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  grid-template-columns: 40px 1fr;
  gap: .625rem;
  flex: 1 100%;

  flex-wrap: nowrap;
}
.menuName {
  display: block;
  flex: 1 1 auto;
  flex-flow: column;
  @screen tablet-max {
	max-width: 185px;
  }
}
.svg {
  width: 1.125rem;
  height: 1.125rem;
  rotate: 180deg;
  flex: 0 1 auto;
  margin: auto 1rem auto .25rem;
  color: rgba(220, 220, 220, 0.5);
  @screen mobile {
	display: none;
  }
}
.photo {

  flex: 0 0 42px;
  padding: 1px;
  border-radius: 1.25rem;
  display: inline-block;
  border: 0 solid transparent;
  //@TODO Убрать как фото загрузщка появится
  img {
	//opacity: 0;
  }
  &[data-directory="admin"] {
	background: var(--gradient-admin);
  }
  &[data-directory="customer"] {
	background: var(--gradient-customer);
  }
  &[data-directory="performer"] {
	background: var(--gradient-performer);
  }


}
.name {
  line-height: 1.29;
  display: flex;
  flex: 1;

  max-width: calc(100vw - 10rem);
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  color: var(--accentColor);
  background: -webkit-linear-gradient(360deg, var(--accentColor) 60%, rgba(0, 253, 173, 0.02) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.first_name {
	//flex: 0 1 fit-content;
  white-space: nowrap;
  margin-right: .5rem;

}
.last_name {
	//flex: 0 2ch;
  white-space: nowrap;
}
.status {
  color: rgba(220, 220, 220, 0.5);
  font-size: .75rem;
  line-height: 1.29;
  //text-transform: lowercase;
}

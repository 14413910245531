
.page-account {
  //padding: .75rem 0 0 0;
  @media screen and (min-width: 961px) {
	//padding: 1rem 0;
  }


  main {
	@apply pt-7;
	//@media screen and (min-width: 744px) {
	//  margin-top: clamp(1rem, 100%, 2rem);
	//}
  }
  .logo-header {
	//display: block;
	//flex-flow: column;
	flex: 0 1 14rem;
	padding: .75rem 1.125rem;
	border-radius: 1.25rem;
	background: var(--gradient-directory);

	a {
	  color: #0C0C0C;
	}

	span {
	  margin-left: 0;
	  line-height: 1.29;
	  font-family: Montserrat, sans-serif;
	  font-weight: 700;
	  text-transform: uppercase;
	  @apply text-xss;
	  color: rgba(23, 22, 22, 0.76);
	  letter-spacing: .1em;
	}
	//@screen lg {
	//  display: flex;
	//}
  }

  & > footer {
	grid-column: 2/4;
	padding-left: 1.25rem;
  }
  section {
	& > [data-variant="dataPadding"], & > [data-variant="textPadding"],  {
	  @screen tablet-max {
		margin: 0 -.75rem;
		border-left: 0;
		border-right: 0;
		[data-panel="body"], [data-panel="header"] {
		  padding-left:  .75rem;
		  padding-right:  .75rem;
		}
		[data-panel="body"]  {
		  [data-panel="tabs"] {
			& > [data-panel="panel"] {
			  margin: 0 -.75rem;
			  padding-left:  .75rem;
			  padding-right:  .75rem;
			  header,  header + [data-panel="body"] {
				padding-left:  .75rem;
				padding-right:  .75rem;
				margin: 0 -.75rem;
			  }
			}
		  }
		  [data-panel="panel"] {
			[data-panel="header"] {
			  padding-left:  .75rem;
			  padding-right:  .75rem;
			}
		  }
		}
	  }
	}
  }
}

.page-intro {
  padding: 2rem 0;
  @media screen and (min-width: 743px) {
	padding: 3.5rem 0 2.25rem;
  }

  main {
	@apply mt-16;
	@media screen and (min-width: 744px) {
	  margin-top: clamp(1rem, 100%, 4rem);
	}
  }
  section[data-variant="centered"] {
	min-height: initial;
  }
  & > footer {
	margin-top: 2rem;
	@media screen and (max-width: 767px){
	  //margin: 2rem 0;
	}
  }
}

.page-price {
  ul {
	@apply justify-start gap-8;
  }
  //max-height: 50vh;
  //overflow: hidden;
  //overflow-y: scroll;
  //max-height: calc(100lvh - 10rem);
  //overflow-y: auto;
}
.table-price {
  background: linear-gradient(182.19deg, #090909 2.89%, rgba(9, 9, 9, 0.28) 101.32%);
}
.carHelper {
  //background: rgba(36, 37, 41, 0.6);
  border: 1px solid #00FFAE;

}
.page-intro_policy {
  & > footer {
	margin-top: 2rem;
	@media screen and (max-width: 767px){
	  //margin: 2rem 0;
	}
  }
}
.notification_cleancar {
  border: 2px solid var(--mantine-color-red-filled) !important;
  border-radius: 8px;
  background: black;
  padding: 1rem 1.5rem;
  &:before {
	content: none !important;
  }
  &.success {
	border-color: var(--mantine-color-green-filled) !important;
  }
}

.cancel-bg {
  background: none !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
}

.mobile_total_block {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;

  /* Color/Gray/1/60% */
  background: rgba(36, 37, 41, 0.6) !important;
  /* Color/Gray/2 */
  border: 1px solid #606163;
  backdrop-filter: blur(2.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14px;

  top: auto;
	*{
	  @apply uppercase;
	}
  overflow: hidden;
 	border-top-left-radius: 1rem !important;
 	border-top-right-radius: 1rem !important;
  li.finance_part__header {
	li {
	font-size: .625rem !important;
	  @apply text-gray-200/50 #{!important};
	}
  }
  .finance_part {
	&:first-child ul {
	  margin-top: .25rem !important;
	  margin-bottom: .25rem !important;
	}
	&:nth-child(2) {
	  .finance_part__title:before {

		content: '';
		width: .625rem;
		height: .625rem;
		display: inline-block;
		background: var(--gradient-customer);
		margin-right: .5rem;
		border-radius: 50% !important;
	  }
	}
	&:nth-child(3) {
	  .finance_part__title:before {
		content: '';
		width: .625rem;
		height: .625rem;
		display: inline-block;
		background: var(--gradient-performer);
		margin-right: .5rem;
		border-radius: 50% !important;
	  }
	}
	&:nth-child(2n) {
	  background: linear-gradient(182.19deg, #090909 2.89%, rgba(9, 9, 9, 0.28) 101.32%);

	}
	> ul {
	  margin: .5rem 1.25rem;
	  display: grid;

	  grid-template-columns: 1fr 1fr 1fr;
	  .finance_part__title {
		@apply text-white text-xs capitalize;


	  }
	  li:first-child:not(.finance_part__title) {
		@apply text-gray-500 text-xss;
	  }
	  li {
		@apply text-white text-xs;
	  }

	}
	&:last-child {
	  ul:first-child {
		* {
		  color: #3FECAE !important;
		}

	  }
	  li:nth-child(2) {
		grid-column: 3;
	  }
	}
  }
  &.close-state {
	.finance_part {
	  ul:first-child {
		max-height: initial !important;
		overflow: hidden !important;
	  }
	  ul:not(:first-child) {
		max-height: 0;
		margin: 0 !important;
		padding: 0 !important;
		overflow: hidden;
	  }
	}
  }
  &.open-state  {
	bottom: 0;
	top: auto !important;
  }
}
.mobile_price_actions {
  background: linear-gradient(180deg, rgb(0 0 0 / -45%) 0, rgba(0, 0, 0, .85) 10%, rgb(0, 0, 0) 100%);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(1px);
  background-position: 0 30px;
  background-repeat: no-repeat;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.user__photo {
  justify-self: center;
  position: relative;
  border-radius: 50%;
  padding: 2px;
  &[data-directory="admin"], &[data-app-type="admin"] {
	background: var(--gradient-admin);
  }

  &[data-directory="performer"], &[data-app-type="performer"] {
	background: var(--gradient-performer);
  }

  &[data-directory="customer"], &[data-app-type="customer"] {
	background: var(--gradient-customer);
  }
  a {
	position: absolute;
	bottom: -.125rem;
	right: -.125rem;
  }
}
